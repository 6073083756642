import React from 'react';
import './Meanderings.css';

function Meanderings() {
  return (
    <div className="meanderings-container">
      <h1>Meanderings</h1>
      <p>This is the Meanderings page.</p>
    </div>
  );
}

export default Meanderings;
