import React from 'react';
import './Home.css';

function Home() {
  return (
    <div className="home-container">
      <h1>Welcome Home</h1>
      <p>This is the homepage of your application.</p>
    </div>
  );
}

export default Home;
